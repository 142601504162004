import ProForm from '@ant-design/pro-form';
import { Select } from 'antd';
import React from 'react'

const CustomProSelect = ({ label, name, options = {}, rules = [], multiple = false, placeholder= '' }) => {

    const finalRules = multiple
    ? rules
    : [
        ...rules,
        {
          validator: (_, value) => {
            if (Array.isArray(value) && value.length > 1) {
              return Promise.reject(new Error('Chỉ được chọn hoặc nhập 1 lựa chọn!'));
            }
            return Promise.resolve();
          },
        },
      ];

  return (
    <ProForm.Item
    name={name}
    label={label}
    rules={finalRules}
  >
    <Select
      mode={'tags'}
      options={options}
      placeholder= {placeholder}
      style={{ width: '100%' }}
    />
  </ProForm.Item>
  )
}

export default CustomProSelect