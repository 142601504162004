import _ from "lodash";
import featherApp from "../../feathersjs/feathersjs";
import {withErrorHandler} from "./common";
import { removeNullValue } from "./student";
import { transformSort } from "./event";

const CheckInService = featherApp.service("event-check-in");

  export const fetchAllCheckIn = withErrorHandler(async (searchData) => {
    return await CheckInService.find(searchData);
  });


  export const fetchEventCheckInByEventIdAndProfileId = withErrorHandler(async ({ eventId, profileId }) => {
    const query = {
      query: {
        eventId: eventId || undefined,
        profileId: profileId || undefined,
        $or: [
          { checkIn: 'notNull' }, 
          { checkOut: 'notNull' }
        ]
      }
    };

    return await fetchAllCheckIn(query);
  });

  export const updateCheckIn = withErrorHandler(async (id, data) => {
    return CheckInService.patch(id, data);
  })
  
  
  export const createCheckIn = withErrorHandler(async (data) => {
    return CheckInService.create(data);
  })

  export const fetchEventCheckInWithSearch = withErrorHandler(async ({ updatedParams, sort, filter }) => {
    removeNullValue(updatedParams);
    removeNullValue(filter);
    const $sort = transformSort(sort);
    const { current = 1, pageSize: $limit = 1000, ...requestParams } = updatedParams;
    const handleRequest = transformEventCheckInRequestParams(requestParams);
    const searchData = createSearchData( current, $limit, handleRequest, $sort, filter);
    
    return fetchAllCheckIn(searchData);
  });
  
  
  const createSearchData = (current, $limit, handleRequest,$sort, filter) => {
    return {
      query: {
        $sort,
        $limit,
        $skip: (current - 1) * $limit,
        ...handleRequest,
        ...filter,
      }
    };
  };

  export const transformEventCheckInRequestParams = (requestParams) => {
    
    return _.transform(requestParams, (result, value, key) => {
      const profileKeys = ['groupId', 'firstName', 'holyName', 'profileID'];

      if (profileKeys.includes(key)) {
        result['profile'] = {
          ...result['profile'],
          [key]: value 
        };
      } 
      else if (value) {
        result[key] = value; 
      }
      return result;
    }, {});
    
  };
  