import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SEND_RESET_SUCCESS,
  SIGNUP_USER_SUCCESS, FETCH_SIGN_IN_USER_PROFILE_SUCCESS, CHANGE_PASSWORD_SUCCESS, UPDATE_PROFILE_COMPLETE
} from "../../constants/ActionTypes";
import {message} from "antd";

const item = localStorage.getItem('authUser');
const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  requestSuccess: null,
  initURL: '',
  authUser: item && JSON.parse(item),
  isProfileComplete: false,
};

const isProfileComplete = (user) => {
  return !!(user?.gender && user?.introduction && user?.avatarUrl);
};

const exec = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      localStorage.removeItem("authUser")
      return {
        ...state,
        requestSuccess:true,
        loader: false,
        // authUser: action.payload
      }
    }
    case SIGNIN_USER_SUCCESS: {
      action.payload && localStorage.setItem("authUser", JSON.stringify(action.payload))
      return {
        ...state,
        loader: false,
        authUser: action.payload,
        isProfileComplete: isProfileComplete(action.payload),
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      localStorage.removeItem("authUser")
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false

      }
    }

    case SEND_RESET_SUCCESS: {
      localStorage.removeItem("authUser")
      return {
        ...state,
        authUser: null,
        requestSuccess: true,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    case CHANGE_PASSWORD_SUCCESS: {
      message.success('Thành công');
      return {
        ...state,
        requestSuccess: true,
        loader: false,
      }
    }
    case SHOW_MESSAGE: {
      message.error(action.payload)
      return {
        ...state,
        3: action.payload,
        requestSuccess: false,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case FETCH_SIGN_IN_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        userProfile: action.payload,
        isProfileComplete: isProfileComplete(action.payload),
      }
    }
    case UPDATE_PROFILE_COMPLETE: {
      return {
        ...state,
        isProfileComplete: true, 
      };
    }
    default:
      return state;
  }
};
export default (state,action)=>{
  const res = exec(state,action);
  return res;
}
