import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, Row, message} from "antd";

import IntlMessages from "util/IntlMessages";
import AuthLayout from "./AuthLayout";
import {createProfileApplication} from "../appRedux/model/profileApplication";
import { ProFormSelect, ProFormSwitch, ProFormText, ProFormTextArea, StepsForm} from "@ant-design/pro-form";
import {useHistory, useLocation} from "react-router-dom";
import ProDatePicker from "../components/ProDatePicker";
import { universityOptions } from "../util/universitiesList";
import { dioceseOptions } from "../util/diocesesList";
import CustomProSelect from "../components/ProSelect";

const RegisterProfile = (props) => {

  const [statusSuccess, setStatusSuccess] = useState({status: null})
  const [isReligious, setIsReligious] = useState(true)
  const [isInterviewer, setIsInterviewer] = useState(false);
  const history = useHistory();

  const location = useLocation();
  const groupOptions = [
    { label: 'Chưa có', value: 0 },
    ...Array.from({ length: 10 }, (_, i) => ({
      label: `Nhóm ${i + 1}`,
      value: i + 1,
    })),
    
  ];

  const yearSchoolOptions = [
    ...Array.from({ length: 6 }, (_, i) => ({
      label: `Năm ${i + 1}`,
      value: i + 1,
    })),
    { label: 'Cao học', value: 'Cao học' },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userType = searchParams.get('userType');
    setIsInterviewer(userType === 'interviewer'); 
  }, [location.search]);

  const onBack = () =>{
    history.push('/signin');
  }
  const formRef = useRef();
  const formMapRef = useRef();

  const handleFinish = async (values) => {
    const dioceseValue = Array.isArray(values.diocese) ? values.diocese[0] : values.diocese;
          values.diocese = dioceseValue
    try {
      await createProfileApplication(values);
      setStatusSuccess({ status: true });
    } catch (error) {
      console.error(error);
      setStatusSuccess({ status: false });
      message.error('Đăng ký không thành công!')
    }
  };

  return (
    <AuthLayout title={'Đăng ký hồ sơ'} statusSuccess={statusSuccess} formInput={
      <div className={'container-register'}>
        <StepsForm
          formMapRef={formMapRef}
          formRef={formRef}
          onFinish={handleFinish}
          stepsRender={() => null}
          containerStyle={{marginTop: '-20px'}}
          submitter={{
            render: (props) => {
              const { step, onSubmit, onPre } = props;
              
              if (!formMapRef.current || !formRef.current) return null;
          
              const [basicInfoFormRef] = formMapRef.current;
              const isReligious = basicInfoFormRef.current?.getFieldValue('religious');
           
              const renderButtons = () => {
                if (step === 0) {
                  return [
                    <Button key="login" onClick={onBack}>
                      Đăng nhập
                    </Button>,
                    isInterviewer && !isReligious ? (
                      <Button key="register" type="primary" onClick={onSubmit}>
                        Đăng ký
                      </Button>
                    ) : (
                      <Button key="next" type="primary" onClick={onSubmit}>
                        Tiếp theo
                      </Button>
                    ),
                  ];
                }
          
                if (step === 1 && isReligious) {
                  return [
                    <Button key="back" onClick={onPre}>
                      Quay lại
                    </Button>,
                    isInterviewer ? (
                      <Button key="register" type="primary" onClick={onSubmit}>
                        Đăng ký
                      </Button>
                    ) : (
                      <Button key="next" type="primary" onClick={onSubmit}>
                        Tiếp theo
                      </Button>
                    ),
                  ];
                }
     
                if ((step === 2 && isReligious) || (step === 1 && !isReligious)) {
                  
                  return [
                    <Button key="back" onClick={onPre}>
                      Quay lại
                    </Button>,
                    <Button key="next" type="primary" onClick={onSubmit}>
                      Tiếp theo
                    </Button>,
                  ];
                }
          
                if (step === 3 || (step === 2 && !isReligious)) {
                  return [
                    <Button key="back" onClick={onPre}>
                      Quay lại
                    </Button>,
                    <Button key="submit" type="primary" onClick={onSubmit}>
                      Đăng ký
                    </Button>,
                  ];
                }
          
                return null;
              };
          
              return renderButtons();
            },
          }}
          
        >
          <StepsForm.StepForm
            name="step1"
            onFinish={async () => {
              const [basicInfoFormRef, religiousFormRef] = formMapRef.current
              const isReligious = basicInfoFormRef.current.getFieldValue('religious')
              if (!isReligious) {
                religiousFormRef.current.resetFields()
              }
              return true
            }}
          >

            <Row gutter={16}>
              <Col span={12}>
                <ProFormText name="lastName" placeholder="Họ"
                  rules={[{required: true, message: <IntlMessages id="message.lastName"/>}]}
                />
              </Col>
              <Col span={12}>
                <ProFormText name="middleName" placeholder="Tên đệm"/>
              </Col>
              <Col span={24}>
                <ProFormText name="firstName" placeholder="Tên"
                  rules={[{required: true, message: <IntlMessages id="message.firstName"/>}]}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                 <ProDatePicker name="birthday"  placeholder='Ngày sinh'
                             rules={[{required: true, message: IntlMessages({ id: "message.birthday" })}]}/>
              </Col>
              <Col span={12}>
                <ProFormText name="mobile" placeholder="Số điện thoại"
                  rules={[{required: true, message: <IntlMessages id="message.mobile"/>,},
                  {pattern: /^0\d{9}$/,  message:  'SĐT không hợp lệ!'},]}
                />

              </Col>
            </Row>


            <ProFormText name="email" placeholder="Email"
              rules={[{required: true, type: 'email', message: <IntlMessages id="message.email"/>,}]}
            />
            <ProFormTextArea name="introduction" placeholder={'Giới thiệu bản thân'}
              rules={[{required: !isInterviewer, message: <IntlMessages id="message.introduction"/>}]}
            />


            <ProFormSwitch
              label={"Bạn có phải là người Công giáo không?"}
              checkedChildren={'Có'}
              unCheckedChildren={"Không"}
              value={isReligious}
              initialValue={true}
              name={'religious'}
              onChange={() => setIsReligious(!isReligious)}
            />
          </StepsForm.StepForm>

          {isReligious && <StepsForm.StepForm
            name="step2"
            onFinish={async () => {
              return true
            }}
          >
            <ProFormText name="holyName" placeholder="Tên thánh"
              rules={[{ required: true, message: 'Vui lòng nhập Tên thánh!' }]}/>
            <ProFormText name="parish" placeholder="Giáo xứ"
              rules={[{ required: true, message: 'Vui lòng nhập Giáo xứ!' }]}
            />
               <CustomProSelect
                name="diocese"
                options={dioceseOptions}
                multiple={false}
                rules={[{ required: true, message: 'Vui lòng chọn Giáo phận!' }]}
                placeholder="Chọn hoặc nhập Giáo phận"
              />

          </StepsForm.StepForm>}

          {!isInterviewer && 
          <>
             <StepsForm.StepForm
                name="step3"
                onFinish={async () => {
                  return true
                }}
              >
                <Row gutter={16}>
                  <Col span={24}>
                        <CustomProSelect
                          name="college"
                          options={universityOptions}
                          multiple={true}
                          rules={[{required: true, message: <IntlMessages id="message.college"/>}]}
                          placeholder="Chọn hoặc nhập tên trường đại học"
                        />
                  </Col>
                  <Col span={24}>
                    <ProFormText name="program" placeholder="Chuyên ngành(Không viết tắt)"
                      rules={[{required: true, message: <IntlMessages id="message.program"/>}]}
                    />
                  </Col>

                    <Col span={24}>
                   
                    <ProFormSelect
                      name="schoolYear"
                      placeholder="Bạn là sinh viên năm thứ?"
                      rules={[{ required: true, message: 'Vui lòng chọn năm học' }]}
                      options={yearSchoolOptions}
                    />
                        
                    </Col>
                    <Col span={24}>
                      <ProDatePicker picker="month" format={"MM/YYYY"} placeholder={'Dự kiến tốt nghiệp'} 
                        name="anticipatedGraduationDate" 
                        rules={[{required: true, message: <IntlMessages id="message.anticipatedGraduationDate"/>}]}/>
                    </Col>

                </Row>

              </StepsForm.StepForm>
           <StepsForm.StepForm
              name="step4" 
              onFinish={async () => {
                return true
              }}
            >
              <ProFormSwitch
                label={"Bạn có cam kết tham gia đầy đủ các buổi sinh hoạt theo quy định của Gia đình sinh viên Tôma Thiện trong năm học tới không?"}
                checkedChildren={'Có'}
                unCheckedChildren={"Không"}
                name={'isCommittedToAttend'}  
                initialValue={false}
              />
              <ProFormSelect
                name="groupId"
                label="Bạn đã có nhóm trước đó chưa?"
                placeholder="Chọn nhóm"
                options= {groupOptions}
                rules={[{ required: true, message: 'Vui lòng chọn nhóm!' }]}
              />
            </StepsForm.StepForm>
          </>
        
         
         }
         

        </StepsForm></div>
    }
        requestSuccessMessage={<IntlMessages id={"app.userAuth.signUpSuccessMessage"}/>}
        requestErrorMessage={<IntlMessages id={"app.userAuth.signUpErrorMessage"}/>}
    />
  );
};

export default RegisterProfile;

