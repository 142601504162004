import {get, post, putRequest} from "../api/common";
import {fetchAllProfiles, sortMapping} from "./profile";
import {withErrorHandler} from "./common";
import _ from "lodash";


export function removeNullValue(obj) {
  Object.keys(obj).forEach(k => {
    !obj[k] && (delete obj[k]);
  });
}


export const fetchAllStudents = withErrorHandler(({ params, sort, filter }) => {
  removeNullValue(params);
  removeNullValue(filter);

  const $sort = handleSort(sort); 
  const { current = 1, pageSize: $limit = 1000, ...requestParams } = params;
  const { groupId: groupValue, ...request } = handleRequest(requestParams);
  const groupId = { $nin: [9999, 8888], ...groupValue };

  const searchData = {
    query: {
      $sort,
      $limit,
      $skip: (current - 1) * $limit,
      groupId,
      ...request,
      ...filter,
    },
  };
  
  return fetchAllProfiles(searchData);
});

export const fetchAllInterviewers = withErrorHandler(({ params, sort, filter }) => {
  removeNullValue(params);
  removeNullValue(filter);

  const $sort = handleSort(sort);
  const { current = 1, pageSize: $limit = 1000, ...requestParams } = params;

  const request = handleRequest(requestParams);
  
  const groupId = { $eq: 8888 };

  const searchData = {
    query: {
      $sort,
      $limit,
      $skip: (current - 1) * $limit,
      groupId,
      ...request,
      ...filter,
    },
  };
  
  return fetchAllProfiles(searchData);
});

export const handleSort = (sort) => {
  return _.transform(sort, (result, value, key) => {
    result[key] = sortMapping[value];
    return result;
  }, {});
};

export const handleRequest = (requestParams) => {
  return _.transform(requestParams, (result, value, key) => {
  
    if (key === 'searchTerm' && value) {
      result[key] = value;
    } else if (key === 'groupId' || key === 'id') {
      result[key] = { '$eq': value };
    } else if (key === 'college' && Array.isArray(value)) {
      result[key] = value;      
    } else if (Array.isArray(value)) {
      result[key] = {
        $or: value.map(item => ({ $iLike: `%${item}%` }))
      };
    } else {
      result[key] = { $iLike: `%${value}%` };
    }
    return result;
  }, {});
};