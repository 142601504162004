import {useSelector} from "react-redux";
import CircularProgress from "../components/CircularProgress";
import React, {useEffect} from "react";
import IntlMessages from "../util/IntlMessages";
import {Link} from "react-router-dom";

const AuthLayout = ({
                      title, statusSuccess,
                      formInput, ignoreRequestSuccess,
                      requestSuccessMessage = <IntlMessages id={"app.userAuth.requestSuccessMessage"}/>,
                      requestErrorMessage = <IntlMessages id={"app.userAuth.requestErrorMessage"}/>, 
                    }) => {

  const {loader, alertMessage, showMessage, authUser, requestSuccess} = useSelector(({auth}) => auth);

  useEffect(() => {
    if (authUser) {
      window.location = `/profile/${authUser.profileId}`
    }
  }, [authUser]);

  const renderContent = () => {
    if (statusSuccess?.status === true) {
      return (
          <div className={"gx-mt-4"}>
            {requestSuccessMessage}
          </div>
      );
    } 
    else if (statusSuccess?.status === false) {
      return (
          <div className={"gx-mt-4"}>
            <p>{requestErrorMessage} </p>
            <Link onClick={() => window.location.reload()}>Đăng ký lại</Link>
          </div>
      );
    }

    if (requestSuccess && !ignoreRequestSuccess) {
      return (
      
          <div className={"gx-mt-4"}>
            {requestSuccessMessage}
          </div>
       
      );
    }

    return (
      <div >
        {formInput}
      </div>
    );
  };

  return (
    <div className="gx-app-login-wrap gx-bg-primary">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <img alt="example" src={require("assets/images/logo-tomathien.png")} className={'gx-app-logo-img'}/>
          </div>

          <div className="gx-app-login-content">
          <div className="gx-app-logo-wid ">
              <h1 className={"gx-main-color"}>{title}</h1>
            </div>
          {renderContent()}
          </div>

          {loader ?
            <div className="gx-loader-view">
              <CircularProgress/>
            </div> : null}
          {/*{showMessage ?*/}
          {/*  message.error(alertMessage.toString()) : null}*/}
        </div>
      </div>
    </div>);
}

export default AuthLayout;
