import featherApp from "../../feathersjs/feathersjs";
import {withErrorHandler} from "./common";
import {fetchEvents} from "./event";
import {removeNullValue} from "./student";
import _ from "lodash";
import {sortMapping} from "./profile";

const ProfileApplication = featherApp.service("profile-applications");


export const createProfileApplication = withErrorHandler(async (values) => {
  return ProfileApplication.create(values);
})

export const updateProfileApplication = withErrorHandler(async (id, value) => {
  return await ProfileApplication.patch(id, value);
})

export const fetchAllProfileApplication = async ({params, sort, filter}) => {
  removeNullValue(params)
  removeNullValue(filter)
  return fetchProfileApplication(({params, sort, filter}));
}

export const fetchProfileApplication = withErrorHandler(async ({params = {}, sort = {}, filter}) => {
  const $sort = _.transform(sort, function (result, value, key) {
    result[key] = sortMapping[value]
    return result
  }, {});
  const {current = 1, pageSize: $limit = 1000, ...requestParams} = params
  const request = _.transform(requestParams, function (result, value, key) {
    if (key === "id" || key === "isVerified") {
      result[key] = {'$eq': value}
    }else if (key === 'college' && Array.isArray(value)) {
      result[key] = value;      
    }else if (Array.isArray(value)) {
      result[key] = {
        $or: value.map(item => ({ $iLike: `%${item}%` }))
      };
    } 
     else
      result[key] = {$like: `%${value}%`}
    return result
  }, {});
  const searchData = {query: {$sort, $limit, $skip: (current - 1) * $limit, ...request, ...filter}}
  return await ProfileApplication.find(searchData)
})


